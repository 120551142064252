import { Navigation } from "../_models/index";

export const navigation: Navigation[] = [
  {
    id: "cms_dashboards",
    title: "Tổng quan",
    translate: "NAV.DASHBOARDS",
    type: "item",
    icon: "user",
    url: "/cms/dashboard",
    exactMatch: true,
  },
  {
    id: "cms_authen",
    title: "Quản trị người dùng",
    translate: "NAV.AUTHEN",
    type: "group",
    icon: "user",
    url: "/cms/authen",
    exactMatch: true,
    children: [
      {
        id: "cms_authen_groupuser",
        title: "Nhóm quyền người dùng",
        translate: "NAV.AUTHEN_LIST.GROUP_USER",
        type: "item",
        url: "/cms/authen/groupuser",
        exactMatch: true
      },
      {
        id: "cms_authen_user",
        title: "Người dùng hệ thống",
        translate: "NAV.AUTHEN_LIST.USER",
        type: "item",
        url: "/cms/authen/user",
        exactMatch: true
      },
      {
        id: "cms_authen_AppPlaform",
        title: "Danh sách ứng dụng nền tảng",
        translate: "NAV.AUTHEN_LIST.APPPLAFORM",
        type: "item",
        url: "/cms/masterdata/AppPlaform",
        exactMatch: true
      },
      {
        id: "cms_authen_connect",
        title: "Quản lý kết nối",
        translate: "NAV.AUTHEN_LIST.CONNECT",
        type: "item",
        url: "/cms/authen/connect",
        exactMatch: true,
      },
    ]
  },
  {
    id: "cms_masterdata",
    title: "Danh mục",
    translate: "NAV.MASTER_DATA",
    type: "group",
    icon: "list",
    url: "/cms/masterdata",
    exactMatch: true,
    children: [
      {
        id: "cms_masterdata_Ethnicity",
        title: "Các dân tộc Việt Nam",
        translate: "NAV.MASTER_DATA_LIST.ETHNICITY",
        type: "item",
        url: "/cms/masterdata/ethnicity",
        exactMatch: true
      },
      {
        id: "cms_masterdata_Religious",
        title: "Tôn giáo",
        translate: "NAV.MASTER_DATA_LIST.REGILIGIOUS",
        type: "item",
        url: "/cms/masterdata/religious",
        exactMatch: true
      },
      {
        id: "cms_masterdata_University",
        title: "Đại học",
        translate: "NAV.MASTER_DATA_LIST.UNIVERSITY",
        type: "item",
        url: "/cms/masterdata/university",
        exactMatch: true
      },
      {
        id: "cms_masterdata_Level",
        title: "Trình độ",
        translate: "NAV.MASTER_DATA_LIST.LEVEL",
        type: "item",
        url: "/cms/masterdata/level",
        exactMatch: true
      },
      {
        id: "cms_masterdata_AcademicTitle",
        title: "Học hàm",
        translate: "NAV.MASTER_DATA_LIST.ACADEMICTITLE",
        type: "item",
        url: "/cms/masterdata/academicTitle",
        exactMatch: true
      },
      {
        id: "cms_masterdata_WideField",
        title: "Lĩnh vực rộng",
        translate: "NAV.MASTER_DATA_LIST.WIEDEFIELD",
        type: "item",
        url: "/cms/masterdata/wideField",
        exactMatch: true
      },
      {
        id: "cms_masterdata_NarrowField",
        title: "Lĩnh vực hẹp",
        translate: "NAV.MASTER_DATA_LIST.NAROWFIELD",
        type: "item",
        url: "/cms/masterdata/narrowField",
        exactMatch: true
      },
      {
        id: "cms_masterdata_ArchiveFolder",
        title: "Thư mục lưu trữ",
        translate: "NAV.MASTER_DATA_LIST.ARCHIVEFOLDER",
        type: "item",
        url: "/cms/masterdata/archiveFolder",
        exactMatch: true
      },
      {
        id: "cms_masterdata_Level4Intermediate",
        title: "Trình độ IV trung cấp cao đẳng",
        translate: "NAV.MASTER_DATA_LIST.LEVEL4INTERMEDIATE",
        type: "item",
        url: "/cms/masterdata/level4Intermediate",
        exactMatch: true
      },
      {
        id: "cms_masterdata_Level4College",
        title: "Trình độ IV cao đẳng đại học",
        translate: "NAV.MASTER_DATA_LIST.LEVEL4COLLEGE",
        type: "item",
        url: "/cms/masterdata/level4College",
        exactMatch: true
      },
      {
        id: "cms_masterdata_Level4Master",
        title: "Trình độ IV thạc sĩ tiến sĩ",
        translate: "NAV.MASTER_DATA_LIST.LEVEL4MASTER",
        type: "item",
        url: "/cms/masterdata/level4Master",
        exactMatch: true
      },
      {
        id: "cms_masterdata_organization",
        title: "Cơ quan/Đơn vị",
        translate: "Cơ quan/Đơn vị",
        type: "item",
        url: "/cms/masterdata/organization",
        exactMatch: true
      },
      {
        id: "cms_masterdata_title",
        title: "Chức vụ",
        translate: "Chức vụ",
        type: "item",
        url: "/cms/masterdata/title",
        exactMatch: true
      },

{
        id: "cms_masterdata_province",
        title: "Danh mục Tỉnh/Thành phố",
        translate: "Danh mục Tỉnh/Thành phố",
        type: "item",
        url: "/cms/masterdata/province",
        exactMatch: true
      },
      {
        id: "cms_masterdata_district",
        title: "Danh mục Quận/Huyện/Thị xã",
        translate: "Danh mục Quận/Huyện/Thị xã",
        type: "item",
        url: "/cms/masterdata/district",
        exactMatch: true
      },
      {
        id: "cms_masterdata_ward",
        title: "Danh mục tỉnh Xã/Phường/Thị Trấn",
        translate: "Danh mục tỉnh Xã/Phường/Thị Trấn",
        type: "item",
        url: "/cms/masterdata/ward",
        exactMatch: true
      },
    ],
  },

];
